import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/qualify-mortgage-san-diego");

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_How to Qualify for a Mortgage in San Diego.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                With its stunning beaches, strong economy, and cultural diversity, it’s no surprise
                that San Diego is one of the most desirable places to live in the United States. But
                does the popularity of this Southern California city make qualifying for a mortgage
                even more competitive?
              </CommonParagraph>
              <CommonParagraph>
                Not necessarily! As long as you understand the key factors that lenders consider,
                current mortgage rates, what to look for when choosing a lender, and when to start
                your real estate search, the odds of securing a mortgage are in your favor.
              </CommonParagraph>
              <CommonParagraph>
                Let’s cover how to qualify for a mortgage in San Diego so that you’re ready to make
                an offer when your dream home comes along.
              </CommonParagraph>
              <Heading type="h2">4 Key Factors to Qualify for a Mortgage</Heading>
              <Heading type="h3">1. Credit Score</Heading>
              <CommonParagraph>
                One of the most critical factors when it comes to qualifying for a San Diego home
                mortgage is your credit score. As it speaks to your ability to repay debt, it’s
                important to protect and improve your score whenever possible. You can boost your
                credit score in many ways, including:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>Paying down existing debt</li>
                <li>Ensuring all bills are paid on time and in full</li>
                <li>Avoiding new credit inquiries</li>
              </ul>
              <CommonParagraph>
                Lenders typically look for a credit score of at least 620, so aim for at least that
                when building your credit and gearing up to{" "}
                <BlogLink url="https://selbysellssd.com/buy-house-san-diego/" text="buy a house" />.
              </CommonParagraph>
              <Heading type="h3">2. Income and Employment History</Heading>
              <CommonParagraph>
                While your credit score speaks to your ability to manage debt, it’s your income that
                provides the means to do so, making it an important part of qualifying for a
                mortgage.
              </CommonParagraph>
              <CommonParagraph>
                You’ll need to prove you have stable income that can support mortgage payments.
                Lenders tend to prefer applicants with at least two years of consistent employment
                in the same industry.
              </CommonParagraph>
              <CommonParagraph>
                In an area like San Diego, where living costs can be high, your income will need to
                sufficiently cover the mortgage and other living expenses. Before applying for a
                mortgage, be sure to get your financial documents in order so you can easily prove
                your income stability.
              </CommonParagraph>
              <Heading type="h3">3. Debt-to-Income Ratio</Heading>
              <CommonParagraph>
                Your debt-to-income ratio, or DTI, is just that; your monthly debt compared to your
                monthly income.
              </CommonParagraph>
              <CommonParagraph>
                It’s another crucial factor that lenders consider when determining your eligibility
                for a San Diego mortgage. While there’s no hard and fast rule, many lenders like to
                see a DTI ratio of 36% or lower. However, if you have great credit and a hefty down
                payment, lenders may accept a higher ratio.
              </CommonParagraph>
              <CommonParagraph>
                To calculate your DTI, add up all your monthly debt payments, such as car loans and
                credit card balances, and divide that total by your gross monthly income. If your
                DTI is high, consider paying down some debt before contacting lenders.
              </CommonParagraph>
              <Heading type="h3">4. Down Payment</Heading>
              <CommonParagraph>
                The size of your down payment impacts your mortgage terms; the larger the payment,
                the lower the loan amount you need. Depending on the area of San Diego you’re
                interested in living, home prices can be high. A substantial down payment can make
                you a more attractive client to a lender.
              </CommonParagraph>
              <CommonParagraph>
                How much you should ideally have for a down payment varies widely, but conventional
                loans typically require a minimum of 3% of the home’s purchase price. However, it’s
                important to note that if you can afford a down payment of at least 20%, you can
                avoid having to pay for private mortgage insurance. That savvy move could save you
                hundreds of dollars each month!
              </CommonParagraph>
              <Heading type="h2">Understanding Mortgage Rates</Heading>
              <CommonParagraph>
                As a potential homebuyer, you’ll want a thorough understanding of{" "}
                <BlogLink
                  url="https://selbysellssd.com/interest-rate-impact-san-diego-real-estate/"
                  text="San Diego mortgage rates"
                />
                , as they can significantly impact the cost of a home over time. Mortgage rates are
                influenced by several factors, including:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>Your credit score</li>
                <li>The economy</li>
                <li>The type of loan you qualify for</li>
                <li>Lender policies</li>
              </ul>
              <CommonParagraph>
                Paying attention to trends and locking in a low rate can save you a considerable
                amount of money. Consider whether an adjustable-rate mortgage (ARM), which starts
                with a lower rate but can increase over time, or a fixed-rate mortgage, one that
                offers stable payments over the loan term, is better suited to your needs and
                financial situation.
              </CommonParagraph>
              <Heading type="h2">What to Look for in a Mortgage Lender</Heading>
              <CommonParagraph>
                Some argue that finding the right lender is as important as finding the right home!
                That’s because a reliable lender can mean the difference between closing on your
                dream home or losing out to another buyer.
              </CommonParagraph>
              <CommonParagraph>
                What should you evaluate when looking at mortgage lenders in San Diego? Consider:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>Their reputation</li>
                <li>The range of loan products they offer</li>
                <li>Fees</li>
                <li>Customer service</li>
              </ul>
              <CommonParagraph>
                And be sure to inquire about their pre-approval process, as having a pre-approval
                letter in hand can give you a competitive edge when you’re ready to attend open
                houses.
              </CommonParagraph>
              <Heading type="h2">When to Look at Real Estate</Heading>
              <CommonParagraph>
                Speaking of open houses, when is the right time to start looking at real estate? In
                a{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/"
                  text="hot market like San Diego"
                />{" "}
                where homes sell fast, you should start looking for homes once you’ve secured your
                mortgage pre-approval letter.
              </CommonParagraph>
              <CommonParagraph>
                Why wait until then? That letter will give you a clear understanding of your budget
                while showing sellers you’re serious about buying.
              </CommonParagraph>
              <Heading type="h2">When You’re Ready to Buy, Turn to The Selby Team</Heading>
              <CommonParagraph>
                While our real estate pros can’t help you secure a mortgage, we can help get you
                into your dream home. When you’re ready to explore San Diego neighborhoods and find
                the perfect place to start a new chapter in your life,{" "}
                <ContactSlideoutLink
                  text="get in touch with our
                realtors"
                />
                ! We can't wait to help welcome you home.
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
